<template>
  <!-- ----------------------------------------------------------------------------- -->
  <!-- Project Assigning -->
  <!-- ----------------------------------------------------------------------------- -->
  <b-card no-body>
    <div class="current-package-box-bg">
      <div>
        <div v-if="checkPermissionBrand('master-sender-names')">
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('subscriptions.allowed_public_sender_name') }}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0" :class="`text-${ featurePlan.random_sender === 1 ? 'green' : 'danger'}`">
                {{ featurePlan.random_sender === 1 ? $t("subscriptions.yes") : $t("subscriptions.no")}}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div>
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('subscriptions.package.customerCanUseApi') }}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0" :class="`text-${ featurePlan.api_access === 1 ? 'green' : 'danger'}`">
                {{ featurePlan.api_access === 1 ? $t("subscriptions.yes") : $t("subscriptions.no")}}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div>
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('subscriptions.package.needSenderNameVerification') }}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0"
                     :class="`text-${ featurePlan.sender_id_verification === 1 ? 'green' : 'danger'}`">
                {{ featurePlan.sender_id_verification === 1 ? $t("subscriptions.yes") : $t("subscriptions.no")}}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div>
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('subscriptions.package.addPreviousSmsUnitsOnNextSubscription')
                }}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0"
                     :class="`text-${ featurePlan.add_previous_balance === 1 ? 'green' : 'danger'}`">
                {{ featurePlan.add_previous_balance === 1 ? $t("subscriptions.yes") : $t("subscriptions.no")}}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div>
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('contacts.groupList.max_contact_list') }}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0 text-green">
                {{ featurePlan.max_contact_list === -1 ? $t('common.Unlimited') :
                featurePlan.max_contact_list.toLocaleString()}}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div>
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('contacts.groupList.max_contact_per_list')}}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0 text-green">
                {{ featurePlan.max_contact_per_list === -1 ? $t('common.Unlimited') :
                featurePlan.max_contact_per_list.toLocaleString()}}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div v-if="featurePlan.url_tracking > 0 && checkPermission('list', 'action', 'url-trackings')">
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0"> {{ $t('contacts.groupList.url_tracking')}}</label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0 text-green">
                {{ featurePlan.url_tracking === -1 ? $t('common.Unlimited') : featurePlan.url_tracking ?
                featurePlan.url_tracking.toLocaleString() : 0 }}
              </label>
            </b-col>
          </b-row>
          <hr class="p-1 m-0">
        </div>
        <div v-if="is_upgrade_url_tracking && checkPermission('create', 'action', 'url-tracking-premium') && checkPermissionBrand('url-tracking-premium')">
          <b-row class="py-1 align-items-center">
            <b-col cols="7">
              <label class="fw-medium mb-0">
                {{ $t('subscriptions.package.upgrade_package_tracking2pro') }}
              </label>
            </b-col>
            <b-col cols="5" class="text-left text-md-right">
              <label class="fw-medium mb-0" :class="`text-${ is_upgrade_url_tracking ? 'green' : 'danger'}`">
                {{ is_upgrade_url_tracking ? $t("subscriptions.yes") : $t("subscriptions.no") }}
              </label>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>
  </b-card>
</template>

<script>
import functions from "@/common/functions";
import brandApi from "@/repository/brandApi";

export default {
  name: "currentPackage",
  props: [
    'featurePlan',
    'is_upgrade_url_tracking'
  ],
  data() {
    return {
      permissionBrand: []
    }
  },
  created() {
    this.getPermissionBrand()
  },
  methods: {
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    getPermissionBrand() {
      brandApi.getBrandPermissions().then(res => {
        this.permissionBrand = res.data
      })
    },
    checkPermissionBrand(permission) {
      return functions.brandCheckPermission(permission, this.permissionBrand)
    }
  }
};
</script>
<style scoped>
.current-package-box-bg {
  border-radius: 12px;
  background-color: #F6F6F6;
  padding: 10px;
}

.spam-text-box {
  border-radius: 6px;
  color: #F6F6F6;
  padding: 1px 4px;
  background-color: var(--primary-color);
}
</style>