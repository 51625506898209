<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-table
            responsive
            class="mb-0 "
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}" class="">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt ? changeDateFormat(data.item.createdAt) : '-' }}
          </template>
          <template #cell(message)="data">
            {{ data.item.message ? data.item.message : '-' }}
          </template>

          <template #cell(action)="data">
            <i style="color: #0d73d9" class="action-button icon-eye mr-2"></i>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <div class="ml-auto">
      <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0"
          size="sm"
      ></b-pagination>
    </div>
  </b-row>
</template>

<script>
import subscriptionApi from "@/repository/subscriptionApi";
import functionsCommon from "@/common/functions";

export default {
  name: "packageHistory",

  data: () => ({
    filter: {
      $skip: 1,
      $limit: 10
    },
    fields: [
      {
        key: "createdAt",
        label: "subscriptions.list.date",
      },
      {
        key: "message",
        label: "subscriptions.list.message",
      },
    ],
    isBusy: false,
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
  }),
  mounted() {
    this.getSubscriptionHistory()
    this.totalRows = this.items.length;
  },
  methods: {
    getSubscriptionHistory() {
      this.isBusy = !this.isBusy
      subscriptionApi.getSubscriptionHistoryList(this.filter).then(resp => {
        this.items = resp?.data
      }).catch(err => {
        throw err
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    changeDateFormat(date) {
      return functionsCommon.changeDateFormat(date)
    },
  }
};
</script>
<style>
.action-button {
  cursor: pointer;
}
</style>
