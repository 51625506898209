<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-table
            responsive
            class="mb-0 "
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}" class="">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt ? changeDateFormat(data.item.createdAt) : '-' }}
          </template>
          <template #cell(title)="data">
            {{ data.item.title ? subString(data.item.title) : '-' }}
          </template>
          <template #cell(invoice_no)="data">
            {{data.item.status === 'approved' || data.item.status === 'pre-credit-paid' ? data.item.billing_no :
            data.item.invoice_no}}
          </template>
          <template #cell(amount)="data">
            {{checkPricePlan(data.item.amount , data.item.currency)}}
          </template>
          <template #cell(status)="data">
            <b-badge pill class="px-2 text-white badge"
                     v-if="data?.item?.status && mapStatus(data.item.status).text !== null"
                     :class="mapStatus(data.item.status).color">
              {{ mapStatus(data.item.status).text }}
            </b-badge>
          </template>
          <template #cell(action)="data">
            <i style="color: #0d73d9" class="action-button icon-eye mr-2"></i>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <div class="ml-auto">
      <b-pagination
          v-model="filter.$skip"
          :total-rows="totalRows"
          :per-page="filter.$limit"
          align="fill"
          class="my-0"
          size="sm"
          @input="getTransactionList"
      ></b-pagination>
    </div>
  </b-row>
</template>

<script>
import subscriptionApi from "@/repository/subscriptionApi";
import functionsCommon from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";

export default {
  name: "transactionHistory",
  components: {EmptyTable},

  data: () => ({
    filter: {
      $skip: 1,
      $limit: 10
    },
    fields: [
      {
        key: "createdAt",
        label: "subscriptions.list.createdAt",
      },
      {
        key: "title",
        label: "subscriptions.list.title",
      },
      {
        key: "invoice_no",
        label: "invoice.list.invoicebill",
      },
      {
        key: "amount",
        label: "subscriptions.list.amount",
      },
      {
        key: "status",
        label: "subscriptions.list.status",
      },
    ],
    isBusy: false,
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
  }),
  mounted() {
    this.getTransactionList()
  },
  methods: {
    getTransactionList() {
      this.items = []
      this.isBusy = !this.isBusy
      const cloneFilter = _.cloneDeep(this.filter)
      subscriptionApi.getTransactionList(cloneFilter).then(resp => {
        this.totalRows = resp?.total;
        this.items = resp?.data
      }).catch(err => {
        throw err
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    changeDateFormat(date) {
      return functionsCommon.changeDateFormat(date)
    },
    subString(data) {
      return data
    },
    checkPricePlan(price, currency) {
      const currency_price = currency.format ? currency?.format?.replace('{PRICE}', '') : ''
      return currency_price + price?.toLocaleString()
    },
    mapStatus(data) {
      switch (data) {
        case 'pending':
          return {
            text: this.$t('common.pending'),
            color: 'bg-warning'
          }
        case 'approved':
          return {
            text: this.$t('common.approved'),
            color: 'bg-success'
          }
        case 'unpaid':
          return {
            text: this.$t('common.unpaid'),
            color: 'bg-gray'
          }
        case 'cancelled':
          return {
            text: this.$t('common.cancelled'),
            color: 'bg-danger'
          }
        case 'pre-credit-approve':
          return {
            text: this.$t('common.pre_credit_approve'),
            color: 'bg-info'
          }
        case 'pre-credit-approved':
          return {
            text: this.$t('common.pre_credit_approve'),
            color: 'bg-info'
          }
        case 'pre-credit-paid':
          return {
            text: this.$t('common.pre_credit_paid'),
            color: 'bg-success'
          }
        default :
          return {
            text: data,
            color: null
          }
      }
    },
  }
};
</script>
<style>
.action-button {
  cursor: pointer;
}

</style>
