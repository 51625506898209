<template>
  <div>
    <div v-if="!is_loading && packageDetail._id">
      <topic-component :topic="$t('SideBarItems.subscriptions.text')"/>
      <b-row>
        <b-col lg="5" sm="12">
          <b-card
              :header='$t("invoice.currentPackage")'
              header-text-variant="white"
              header-bg-variant="primary"
              class="mb-3"
              header-class="text-20"
          >
            <b-row class="mb-2">
              <b-col class="text-left">
                <div class="text-package-name text-bold">
                  {{ currentPackage.name }}
                </div>
                <div class="text-danger font-size-12">
                  {{ $t('subscriptions.expireDate') }}
                  {{ changeDateFormat(currentPackage.expire_at) }}
                </div>
              </b-col>
            </b-row>
            <b-row class="font-size-18 mb-3">
              <b-col class="text-left text-credit-price">
                {{ $t('subscriptions.package_price') }}
              </b-col>
              <b-col class="text-right">
                <div class="text-credit text-bold">
                  {{ currentPackage.currency }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="6" lg="6">
                <div class="box-send mb-2" align="center">
                  <h5 class="text-primary">{{ $t("subscriptions.smsCredit") }}</h5>
                  <h4 class="text-bold">{{ currentPackage.sms_max }}</h4>
                </div>
              </b-col>
              <b-col sm="12" md="6" lg="6">
                <div class="box-send mb-2 " align="center">
                  <h5 class="text-primary">{{ $t("subscriptions.textMessage") }}</h5>
                  <h4 class="text-bold">{{ creditPerMsg }} {{ $t("subscriptions.creditPerSMS") }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row class="current-package-box-bg  ">
              <b-col xl="6" lg="12" md="12" class="mt-2">
                <b-button block variant="gradient"
                          :class="{'mt-2': innerWidth <= mobileWidth}"
                          v-if="checkPermission('re-new', 'action', 'subscriptions')"
                          class="px-4" size="lg" @click="renewPackage(packageDetail)"
                          :disabled="packageDetail.status !== 1 || packageDetail.type === 'trial' || packageDetail.is_custom || packageDetail.is_default === 1">
                  {{
                  $t('invoice.renew')
                  }}
                </b-button>
              </b-col>
              <b-col xl="6" lg="12" md="12" class="mt-2">
                <b-button block variant="dark" class="px-4" :class="{'mt-2': innerWidth <= mobileWidth}" size="lg"
                          v-if="checkPermission('new-subscribe', 'action', 'subscriptions')"
                          @click="changePackage(packageDetail)">
                  {{ $t('invoice.changePackage') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <h4 class="text-primary">{{ $t("invoice.detail") }}</h4>
            <current-package :feature-plan="feature_plan" :is_upgrade_url_tracking="is_upgrade_url_tracking"/>
          </b-card>
        </b-col>
        <b-col lg="7" sm="12">
          <b-card>
            <b-tabs content-class="mt-3">
              <b-tab :title='$t("subscriptions.transactions")'>
                <transaction-history/>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else class="text-center text-primary my-2"
         style="position: absolute;transform: translate(-50%, -50%); left: 50%;top: 50%;">
      <b-spinner style="width: 3rem; height: 3rem" class="align-middle mr-2"/>
      <strong> {{ $t('loading') }}</strong>
    </div>
    <modal-tutorial/>
  </div>
</template>

<script>
import CurrentPackage from "@/views/subscriptions/currentPackage";
import TopicComponent from "@/components/common/topicComponent";
import PackageHistory from "@/views/subscriptions/history";
import TransactionHistory from "@/views/subscriptions/transactions";
import subscriptionApi from "@/repository/subscriptionApi";
import SwitchComponent from "@/components/common/switchComponent";
import InputComponent from "@/components/common/inputComponent";
import authApi from "@/repository/authApi";
import functions from "@/common/functions";
import SelectComponent from "@/components/common/selectComponent";
import masterData from "@/common/masterData.json";
import {mapState} from "vuex";
import ModalTutorial from "@/views/subscriptions/modalTutorial.vue";
import functionsCommon from "@/common/functions";

export default {
  name: "index",
  components: {
    ModalTutorial,
    SelectComponent,
    InputComponent,
    SwitchComponent, TransactionHistory, PackageHistory, TopicComponent, CurrentPackage
  },
  data() {
    return {
      currentPackage: {
        name: '',
        price: 0,
        sms_credit: '',
        text_message: '',
        sms_max: '',
        currency: '',
        status: '',
        expire_at: '',
      },
      creditPerMsg: 1,
      feature_plan: {},
      packageDetail: {},
      notify_form: {
        is_notify: false,
        expires_in: 1
      },
      activeList: masterData.activeList,
      customer: {},
      err_exp_notify: '',
      exp_options: [
        {
          value: 1,
          en: "1 Day",
          th: "1 วัน",
          cn: "1 天",
          vn: "1 Ngày",
          my: "1 Hari",
          id: "1 Hari",
        },
        {
          value: 7,
          en: "7 Day",
          th: "7 วัน",
          cn: "7 天",
          vn: "7 Ngày",
          my: "7 Hari",
          id: "7 Hari",
        },
        {
          value: 15,
          en: "15 Day",
          th: "15 วัน",
          cn: "15 天",
          vn: "15 Ngày",
          my: "15 Hari",
          id: "15 Hari",
        },
        {
          value: 30,
          en: "30 Day",
          th: "30 วัน",
          cn: "30 天",
          vn: "30 Ngày",
          my: "30 Hari",
          id: "30 Hari",
        }
      ],
      is_upgrade_url_tracking: false,
      is_loading: false
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    }),
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  mounted() {
    this.getCurrentPackageDetail()

  },
  methods: {
    changePackage(packageDetail) {
      let currentPack = packageDetail?._id ? `?current=${packageDetail?._id}` : ''
      this.$router.push(`/Subscriptions/Package${currentPack}`).catch(() => {
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    getCurrentPackageDetail() {
      this.is_loading = true
      subscriptionApi.getCurrentPackageDetail().then(resp => {
        this.packageDetail = resp
        const rawDataOptions = _.get(resp, 'options', {})
        const rawDataCurrency = _.get(resp, 'currency', {})
        const rawDataFeaturePlan = _.get(resp, 'feature_plan', {})
        if (_.isNil(resp?._id)) {
          this.$router.push('/Subscriptions/Package').catch(() => {
          })
        } else {
          if (this.userInfo?.identity_verify_status !== 'verified' && !this.getCookie('ACCEPT_TUTORIAL')) {
            this.$bvModal.show('modal-tutorial')
          }
          this.currentPackage = {
            ...this.currentPackage,
            name: this.packageDetail?.name,
            price: this.packageDetail?.price,
            sms_max: rawDataOptions?.sms_max === -1 ? 'Unlimited' : rawDataOptions?.sms_max?.toLocaleString(),
            currency: rawDataCurrency ? this.packageDetail.type === 'trial' ? "Free trial" : rawDataCurrency?.format?.replace('{PRICE}', this.packageDetail?.price?.toLocaleString()) : '',
            status: this.packageDetail?.status,
            expire_at: this.packageDetail?.expire_at,
          }
          this.feature_plan = rawDataFeaturePlan
          this.creditPerMsg = rawDataOptions?.plain_sms
        }
        return resp
      }).then(resp => {
        if (resp) {
          this.getCustomerProfile()
        }
      }).finally(() => {
        this.is_loading = false
      })
    },
    renewPackage(packageDetail) {
      sessionStorage.setItem('_pack', JSON.stringify(packageDetail))
      this.$router.push(`/Subscriptions/Checkout?subscription=renew`).catch(() => {
      })
    },
    getCustomerProfile() {
      authApi.getCustomerProfile().then(resp => {
        if (resp) {
          this.customer = resp?._id
          this.notify_form = {
            ...this.notify_form,
            is_notify: resp?.plan_expires?.is_notify,
            expires_in: resp?.plan_expires?.expires_in
          }
          if (resp?.is_upgrade_url) {
            this.is_upgrade_url_tracking = resp?.is_upgrade_url
          } else {
            this.is_upgrade_url_tracking = false
          }
        }
      })
    },
    changeDateFormat(date) {
      return date ? functions.changeDateNotTimeFormat(date) : '-'
    },
    changeName(pack_name) {
      return {
        full_name: pack_name,
        short_name: _.truncate(pack_name, {'length': 30, 'omission': '...'})
      }
    },
    getCookie (name) {
      return functionsCommon?.getCookie(name)
    }
  },
  watch: {
    'notify_form.expires_in'(newVal) {
      let int = Number.isInteger(Number(newVal))
      if (int) {
        this.err_exp_notify = ""
      } else {
        this.err_exp_notify = this.$t("subscriptions.please_fill_information")
      }
    }
  }
}
</script>

<style>
.text-20 {
  font-size: 18px;
  padding: 5px 5px 5px 20px !important;
  border-radius: 14px 14px 0px 0px !important;
}

.text-credit {
  background: linear-gradient(179.88deg, var(--primary-color) 0.11%, var(--secondary-color) 99.89%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.text-credit-price {
  background: linear-gradient(179.88deg, #586A8F 0.11%, var(--dark) 99.89%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 17px;
}

#exp > .form-group {
  margin-bottom: 0rem !important;
}

.load-content-card {
  min-height: calc(100vh - 200px);
}

.text-18 {
  font-size: 22px;
  font-weight: 900;
}

.text-package-name {
  color: #586A8F;
  font-weight: bold;
  font-size: 18px;
  -webkit-background-clip: text;
}

.data-total {
  font-size: 30px;
}

.data-total-40 {
  font-size: 40px;
}

.box-send {
  /*background-image: linear-gradient(to left, #469fff, #d3f5fd);*/
  background: #F6F6F6;
  border-radius: 12px;
  padding: 20px;
}
</style>